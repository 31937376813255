import React from "react";
import {
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Row } from "reactstrap";
import {
  applyClassToCharacters,
  getStringFromJSX,
} from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import NaqeebResponsibilityRequestForm from "./NaqeebResponsibilityRequestForm/NaqeebResponsibilityRequestForm";
import MuavaneenResponsibilityRequestForm from "./MuavaneenResponsibilityRequestForm/MuavaneenResponsibilityRequestForm";
import { IoArrowBackSharp } from "react-icons/io5";

const MemberResponsibilityRequest = ({ history }) => {
  const location = useLocation();
  const responsibilityValues = location.state?.responsibilityValues;

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox">
        <div className="ibox-title">
          <Row>
            <Col md="10">
              <h4 style={{ fontSize: 20, fontWeight: "normal" }}>
                {applyClassToCharacters(
                  getStringFromJSX(
                    <GetLanguageString
                      props="member__request_title_assign_responsibility"
                      value={
                        responsibilityValues.displayName
                          ? `(${responsibilityValues.displayName})` +
                            responsibilityValues.responsibility.label
                          : responsibilityValues.responsibility.label
                      }
                    />
                  ),
                  "ur"
                )}
              </h4>
              <h4 style={{ fontWeight: "lighter" }}>
                {applyClassToCharacters(
                  getStringFromJSX(
                    <GetLanguageString
                      props="member__request_title_text_assign_responsibility"
                      value={
                        responsibilityValues.displayName
                          ? `(${responsibilityValues.displayName})` +
                            responsibilityValues.responsibility.label
                          : responsibilityValues.responsibility.label
                      }
                    />
                  ),
                  "ur"
                )}
              </h4>
            </Col>

            <Col md="2">
              <Button
                color={"default"}
                size="m"
                style={{ marginTop: 20 }}
                className={GetCustomStyles().btn_style}
                onClick={() => history.goBack()}
              >
                {" "}
                <IoArrowBackSharp
                  color="#18A689"
                  size={15}
                  style={{ marginRight: 5, marginBottom: 1 }}
                />
                {<GetLanguageString props="courses_participants_back" />}
              </Button>
            </Col>
          </Row>
        </div>
        {responsibilityValues.responsibility.label === "نقیب" ? (
          <NaqeebResponsibilityRequestForm
            responsibilityValues={responsibilityValues}
          />
        ) : responsibilityValues.responsibility.label1 === "muavin" ||
          responsibilityValues.responsibility.label1 === "secretary_finance" ||
          responsibilityValues.responsibility.label1 === "secretary" ? (
          <MuavaneenResponsibilityRequestForm
            responsibilityValues={responsibilityValues}
          />
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(MemberResponsibilityRequest);
