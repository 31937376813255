import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { MdDoneAll } from "react-icons/md";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import swal from "sweetalert";
import {
  applyClassToCharacters,
  convertToDateString,
  getStringFromJSX,
  isUrdu,
  LoadingSpinner,
} from "../../../../constants/const";
import { GetLanguageString } from "../../../helper/Components";
import { GetSelectedLanguage } from "../../../helper/Method";
import { addMember } from "../../ApiMember";
import ResponsibilityErrorMessage from "../ResponsibilityErrorMessage";
import FormStepDeen from "./FormStepDeen";
import FormStepTanzeem from "./FormStepTanzeem";
import FormStepTasurat from "./FormStepTasurat";

const NaqeebResponsibilityRequestForm = ({ responsibilityValues, history }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [buttonDisable, setButtonDisable] = useState(false);
  const selectedLanguage = GetSelectedLanguage();
  const [displayMessageModal, setDisplayMessageModal] = useState(false);
  const notify = (string) => toast.success(string);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    memberRegNo: "",
    memberName: "",
    previousNaqeeb: "",
    isOneYearMultazim: false,
    isMultzimCourseCompleted: false,
    isNuqabaCourseCompleted: false,
    isMultazimNisaabCompleted: false,
    isRegularInIjtimaat: false,
    isInformingNotAttendingIjtimaInCaseOfEmergency: false,
    isGivingFundRegularly: false,
    isRespectfulInGivingSuggestion: false,
    isPrayingObligatoryPrayersInMasjid: false,
    taluqMaAllah: "",
    isMaintainingPhysicallyAccordingToSunnah: false,
    isMaintainingPardah: false,
    sourceOfIncome: "",
    financialStatus: "",
    isReadNizamUlAmalAndRahnumaAsool: false,
    otherInformation: "",
    dateOfRequest: responsibilityValues.responsibilityDate,
    muqamiAmeerName: "",
    muqamiAmeerFeedback: "",
    muqamiTanzeemName: "",
    ameerHalqaName: "",
    ameerHalqaFeedback: "",
  });

  useEffect(() => {
    setLoading(true);
    addMember()
      .MemberResponsibilityRequestNaqeebFormData(
        responsibilityValues.memberId,
        responsibilityValues.responsibility.value,
        responsibilityValues.unit.value
      )
      .then((res) => {
        setValues({
          ...values,
          memberName: res.data.memberName,
          memberRegNo: res.data.memberRegNo,
          previousNaqeeb: res.data.previousNaqeeb,
          isOneYearMultazim: res.data.isOneYearMultazim,
          isMultzimCourseCompleted: res.data.isMultzimCourseCompleted,
          isMultazimNisaabCompleted: res.data.isMultazimNisaabCompleted,
          isNuqabaCourseCompleted: res.data.isNuqabaCourseCompleted,
          muqamiAmeerName: res.data.muqamiAmeerName,
          muqamiTanzeemName: res.data.muqamiTanzeemName,
          ameerHalqaName: res.data.ameerHalqaName,
          sourceOfIncome: res.data.sourceOfIncome,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [responsibilityValues]);

  const nextStep = () => {
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCheckBoxes = (e) => {
    const { name, value } = e.target;
    let val = value === "true";
    setValues({
      ...values,
      [name]: val,
    });
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <FormStepDeen
            setValues={setValues}
            values={values}
            handleCheckBoxes={handleCheckBoxes}
          />
        );
      case 2:
        return (
          <FormStepTanzeem
            values={values}
            setValues={setValues}
            handleCheckBoxes={handleCheckBoxes}
            handleInputChange={handleInputChange}
          />
        );
      case 3:
        return <FormStepTasurat values={values} />;
      default:
        return null;
    }
  };
  const handleSubmit = () => {
    values.proposedResponsibility = responsibilityValues.responsibility;
    values.dateOfRequest = convertToDateString(new Date());

    responsibilityValues.naqeebRequest = values;

    setButtonDisable(true);
    setLoading(true);
    addMember()
      .addMemberResponsibility(responsibilityValues, false)
      .then((res) => {
        if (res.data.requestExists) {
          swal({
            title: t("member__request_exists_for_assign_responsibility"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "error",
            buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
          });
          history.goBack();
          setLoading(false);
          setButtonDisable(false);
        } else if (res.data === "Invalid Data") {
          notify(res.data);
          setLoading(false);
          setButtonDisable(false);
        } else if (res.data.cannotAssignResponsibility) {
          swal({
            title: t(
              "member__member_responsibility_error_cannot_assign_resposnibility"
            ),
            icon: "error",
            buttons: "OK",
            className: GetSelectedLanguage() === "en" ? "" : "ur",
          });
          setButtonDisable(false);
          setLoading(false);
        } else if (
          !res.data.thisMemberHaveResponsibility &&
          !res.data.otherMemberHaveResponsibility &&
          !res.data.isUsraMavin
        ) {
          swal({
            title: t("member__request_success_for_assign_responsibility"),
            icon: "success",
            buttons: "OK",
            className: GetSelectedLanguage() === "en" ? "" : "ur",
          });
          history.goBack();
          setLoading(false);
          setButtonDisable(false);
        } else {
          setErrorMessages(res.data);
          setDisplayMessageModal(true);
          setLoading(false);
          setButtonDisable(false);
        }
      })
      .catch((err) => {
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        console.log(err);
        setLoading(false);
        setButtonDisable(false);
      });
    console.log(responsibilityValues);
  };

  return (
    <div className="ibox-content">
      {!loading ? (
        <>
          {displayMessageModal ? (
            <ResponsibilityErrorMessage
              setDisplayMessageModal={setDisplayMessageModal}
              data={errorMessages}
              displayName={responsibilityValues.displayName}
            />
          ) : null}
          <Row>
            <Col md="4">
              <FormGroup>
                <Label className="col-form-label">
                  <GetLanguageString props={"common_name"} />
                </Label>
                <Input
                  type="text"
                  name="memberName"
                  disabled
                  className={
                    isUrdu(values.memberName) ? "ur no-drag" : "no-drag"
                  }
                  value={values.memberName}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label className="col-form-label">
                  <GetLanguageString props={"member_reg_number"} />
                </Label>
                <Input
                  type="text"
                  name="memberRegNo"
                  disabled
                  className={
                    isUrdu(values.memberRegNo) ? "ur no-drag" : "no-drag"
                  }
                  value={getStringFromJSX(
                    applyClassToCharacters(values.memberRegNo, "ur")
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label className="col-form-label">
                  <GetLanguageString
                    props={
                      "member__request_previous_naqeeb_assign_responsibility"
                    }
                  />
                </Label>
                <Input
                  type="text"
                  name="previousNaqeeb"
                  disabled
                  className={
                    isUrdu(values.previousNaqeeb) ? "ur no-drag" : "no-drag"
                  }
                  value={values.previousNaqeeb}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr></hr>
          <Row className="mb-4">
            {[1, 2, 3].map((step) => (
              <Col key={step}>
                <Button
                  block
                  className="text-left position-relative py-2"
                  style={{
                    backgroundColor:
                      currentStep >= step ? "#507881" : "#5d8f9b", // Darker for active, lighter for inactive
                    borderColor: currentStep >= step ? "#507881" : "#5d8f9b", // Uniform border color
                    color: "#FFFFFF", // White text for all
                    cursor: currentStep < step ? "not-allowed" : "pointer",
                    transition: "all 0.3s ease-in-out",
                    boxShadow:
                      currentStep >= step
                        ? "0 4px 15px rgba(80, 120, 129, 0.4)" // Subtle shadow for active
                        : "none",
                  }}
                  onMouseEnter={(e) => {
                    if (currentStep >= step) {
                      e.target.style.backgroundColor = "#33565E"; // Slightly darker on hover
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (currentStep >= step) {
                      e.target.style.backgroundColor = "#507881"; // Revert to original color
                    }
                  }}
                  disabled={currentStep < step}
                >
                  <span
                    className="font-weight-bold ur"
                    style={{ fontSize: 18 }}
                  >
                    {step === 1
                      ? "دینی اعتبار سے"
                      : step === 2
                      ? "تنظیمی اعتبار سے"
                      : "امیر کے تاثرات"}
                  </span>
                </Button>
              </Col>
            ))}
          </Row>

          <div className="ibox-content p-4 mb-4" style={{ minHeight: "200px" }}>
            {renderStepContent()}
          </div>

          {/* Navigation */}
          <div className="d-flex justify-content-between">
            <Button
              color="default"
              onClick={prevStep}
              disabled={currentStep === 1}
              className="px-4"
            >
              {selectedLanguage === "ur" ? (
                <IoArrowForwardSharp
                  color={currentStep === 1 ? "grey" : "#507881"}
                  size={15}
                  style={{ marginLeft: 5, marginBottom: 1 }}
                />
              ) : (
                <IoArrowBackSharp
                  color="#507881"
                  size={15}
                  style={{ marginRight: 5, marginBottom: 1 }}
                />
              )}
              <GetLanguageString props={"Previous"} />
            </Button>
            {currentStep === 3 ? (
              <Button
                color={"default"}
                onClick={handleSubmit}
                disabled={buttonDisable}
                className="px-4"
              >
                <MdDoneAll
                  color={currentStep === 1 ? "grey" : "#507881"}
                  size={17}
                  style={{ marginRight: 5, marginBottom: 1 }}
                />
                <GetLanguageString props={"Submit"} />
              </Button>
            ) : (
              <Button
                color={"default"}
                onClick={nextStep}
                disabled={currentStep === 3}
                className="px-4"
              >
                <GetLanguageString props={"Next"} />
                {selectedLanguage === "ur" ? (
                  <IoArrowBackSharp
                    color={currentStep === 1 ? "grey" : "#507881"}
                    size={15}
                    style={{ marginRight: 5, marginBottom: 1 }}
                  />
                ) : (
                  <IoArrowForwardSharp
                    color="#507881"
                    size={15}
                    style={{ marginLeft: 5, marginBottom: 1 }}
                  />
                )}
              </Button>
            )}
          </div>
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(NaqeebResponsibilityRequestForm);
