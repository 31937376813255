import moment from "moment";
import "moment/locale/ur";
import React from "react";
import { convertUTCToLocalTime } from "../../../../constants/const";
import { personalInfo, section } from "../../../../content/css/customCss";

const PrintNaqeebForm = ({ printData }) => {
  const textStyle = { fontSize: "16px" };

  const questions = [
    {
      label: "ملتزم رفیق قرار پائے کم از کم ایک سال کا عرصہ گزر چکا ہے؟",
      value: printData.IsOneYearMultazim ? "جی ہاں" : "نہیں ",
    },
    {
      label: "ملتزم تربیتی کورس مکمل کر چکے ہیں؟",
      value: printData.IsMultzimCourseCompleted ? "جی ہاں" : "نہیں ",
    },
    {
      label: "نقباء کورس کر چکے ہیں؟",
      value: printData.IsNuqabaCourseCompleted ? "جی ہاں" : "نہیں ",
    },
    {
      label:
        "ملتزم نصاب میں شامل تمام کتب کے مضامین کا مطالعہ یا سماعت مکمل کر چکے ہیں؟",
      value: printData.IsMultazimNisaabCompleted ? "جی ہاں" : "نہیں ",
    },
    {
      label: "کیا اجتماعات میں باقاعدگی سے (کل وقتی اور بروقت) شریک ہوتے ہیں؟",
      value: printData.IsRegularInIjtimaat ? "جی ہاں" : "نہیں ",
    },
    {
      label:
        "بامر مجبوری شرکت نہ کر سکنے پر پیشگی رخصت طلب کرنے کا اہتمام کرتے ہیں؟",
      value: printData.IsInformingNotAttendingIjtimaInCaseOfEmergency
        ? "جی ہاں"
        : "نہیں ",
    },
    {
      label: "کیا باقاعدگی ہے انفاق کی سعادت حاصل کرتے ہیں؟",
      value: printData.IsGivingFundRegularly ? "جی ہاں" : "نہیں ",
    },
    {
      label: "مشاورت و تنقید کے ضمن میں آداب کو ملحوظ ررکھتے ہیں؟",
      value: printData.IsRespectfulInGivingSuggestion ? "جی ہاں" : "نہیں ",
    },
    {
      label:
        "فرض نمازوں (خصوصاً فجر و عشاء) کو پابندی کے ساتھ باجماعت ادا کرتے ہیں؟",
      value: printData.IsPrayingObligatoryPrayersInMasjid ? "جی ہاں" : "نہیں ",
    },
    {
      label: "تعلق مع اللہ (عبادات / اخلاقیات / معاملات) کی کیفیت؟",
      value: printData.TaluqMaAllah,
    },
    {
      label: "وضع قطع (داڑھی و لباس وغیرہ) میں سنت نبوی کا لحاظ رکھتے ہیں؟",
      value: printData.IsMaintainingPhysicallyAccordingToSunnah
        ? "جی ہاں"
        : "نہیں ",
    },
    {
      label: "غیر ملحوط معاشرت اور پردے کا کس قدر اہتمام کرتے ہیں؟",
      value: printData.IsMaintainingPardah ? "جی ہاں" : "نہیں ",
    },
    {
      label: "ذریعہ معاش کیا ہے؟",
      value: printData.SourceOfIncome,
    },
    {
      label: "مالی و دیگر معاملات کیسے ہیں؟",
      value: printData.FinancialStatus ? "جی ہاں" : "نہیں ",
    },
    {
      label: "نظام العمل اور راہنما اصول کا مطالعہ کر لیا ہے؟",
      value: printData.IsReadNizamUlAmalAndRahnumaAsool ? "جی ہاں" : "نہیں ",
    },
  ];

  // Function to convert index to Roman numeral
  const toRoman = (num) => {
    const roman = [
      "i",
      "ii",
      "iii",
      "iv",
      "v",
      "vi",
      "vii",
      "viii",
      "ix",
      "x",
      "xi",
      "xii",
      "xiii",
      "xiv",
      "xv",
    ];
    return num < 16 ? roman[num - 1] : num;
  };
  return (
    <div className="ur" style={{ direction: "rtl" }}>
      <h2 className="text-center">
        <u>جائزہ فارم براۓ تقرر "نقیب"</u>
      </h2>
      <p
        className="m-3"
        style={{ ...textStyle, marginRight: 40, marginLeft: 40 }}
      >
        مقامی امیر کی جانب سے کسی ملتزم رفیق کو نقیب قرار دینے کی تجویز / سفارش
        موصول ہونے پر امیر حلقہ اس کی توثیق و اجازت سے قبل متعلقہ رفیق کے حوالے
        سے درج ذیل امور / پہلوؤں کا جائزہ لے لیا کرے:
      </p>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "10%",
                fontSize: "16px",
              }}
            >
              نام:
            </span>
            <span
              style={{
                display: "inline-block",
                width: "70%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "17px",
              }}
            >
              {printData.MemberName !== null && printData.MemberName !== ""
                ? printData.MemberName
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                display: "inline-block",
                width: "20%",
                fontSize: "16px",
              }}
            >
              رجسٹریشن نمبر :
            </span>
            <span
              style={{
                display: "inline-block",
                textAlign: "center",
                width: "60%",
                borderBottom: "1px dotted black",
                fontSize: "17px",
              }}
            >
              {printData.MemberRegNo !== null && printData.MemberRegNo !== ""
                ? printData.MemberRegNo
                : "-"}
            </span>
          </p>{" "}
        </div>
      </div>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "20%",
                fontSize: "16px",
              }}
            >
              کس نقیب کی جگہ تقرر کیا گیا؟
            </span>
            <span
              style={{
                display: "inline-block",
                width: "65%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "17px",
              }}
            >
              {printData.PreviousNaqeeb !== null &&
              printData.PreviousNaqeeb !== ""
                ? printData.PreviousNaqeeb
                : "-"}
            </span>
          </p>{" "}
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "auto",
          direction: "rtl",
        }}
      >
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tbody>
            {questions.map((question, index) => (
              <tr key={index} style={{ verticalAlign: "top" }}>
                {/* Roman Numerals */}
                <td className="text-center" style={textStyle}>
                  ({toRoman(index + 1)})
                </td>
                <td
                  style={{
                    width: "98%",
                    display: "flex",
                    // alignItems: "center",
                  }}
                >
                  <span style={textStyle}>{question.label}</span>
                  <span
                    style={{
                      ...textStyle,
                      flexGrow: 1,
                      borderBottom: "1px dotted black",
                      marginRight: "10px",
                      textAlign: "center",
                    }}
                  >
                    {question.value}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br></br>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "10%",
                fontSize: "16px",
              }}
            >
              دیگر احوال و امور
            </span>
            <span
              style={{
                display: "inline-block",
                width: "75%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "17px",
              }}
            >
              {printData.OtherInformation !== null &&
              printData.OtherInformation !== ""
                ? printData.OtherInformation
                : "-"}
            </span>
          </p>{" "}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          border: "1px solid #999999",
          marginRight: 60,
          marginLeft: 60,
          height: "150px",
        }}
      >
        {/* Left Box */}
        <div
          style={{
            flex: 1,
            padding: "10px",
            borderLeft: "1px solid  #999999",
          }}
        >
          <div style={section}>
            {" "}
            <p>
              <span
                className="ur"
                style={{
                  display: "inline-block",
                  width: "65%",
                  fontSize: "18px",
                }}
              >
                تاثرات مقامی امیر :
              </span>
              <span
                style={{
                  display: "inline-block",
                  width: "30%",
                  marginRight: 10,
                }}
              >
                <u>
                  {moment(
                    convertUTCToLocalTime(printData.DateOfRequest)
                  ).format("DD/MM/yyyy")}
                </u>
              </span>
            </p>{" "}
          </div>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "85%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "15px",
                  }}
                >
                  {printData.MuqamiAmeerFeedback !== null &&
                  printData.MuqamiAmeerFeedback !== ""
                    ? printData.MuqamiAmeerFeedback
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
          <br></br>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "25%",
                    fontSize: "16px",
                  }}
                >
                  نام مقامی امیر :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "60%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "17px",
                  }}
                >
                  {printData.MuqamiAmeerName !== null &&
                  printData.MuqamiAmeerName !== ""
                    ? printData.MuqamiAmeerName
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "25%",
                    fontSize: "16px",
                  }}
                >
                  مقامی تنظیم :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "60%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "17px",
                  }}
                >
                  {printData.MuqamiTanzeemName !== null &&
                  printData.MuqamiTanzeemName !== ""
                    ? printData.MuqamiTanzeemName
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
        </div>

        {/* Right Box */}
        <div
          style={{
            flex: 1,
            padding: "10px",
          }}
        >
          <div style={section}>
            {" "}
            <p>
              <span
                className="ur"
                style={{
                  display: "inline-block",
                  width: "65%",
                  fontSize: "18px",
                }}
              >
                توثیق :
              </span>
              <span
                style={{
                  display: "inline-block",
                  width: "30%",
                  marginRight: 10,
                }}
              >
                <u>
                  {moment(convertUTCToLocalTime(new Date())).format(
                    "DD/MM/yyyy"
                  )}
                </u>
              </span>
            </p>{" "}
          </div>{" "}
          <br></br>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "85%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "15px",
                  }}
                >
                  {printData.AmeerHalqaFeedback !== null &&
                  printData.AmeerHalqaFeedback !== ""
                    ? printData.AmeerHalqaFeedback
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
          <br></br>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "25%",
                    fontSize: "16px",
                  }}
                >
                  نام امیر حلقہ :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "60%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "18px",
                  }}
                >
                  {printData.AmeerHalqaName !== null &&
                  printData.AmeerHalqaName !== ""
                    ? printData.AmeerHalqaName
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div
        style={{
          ...personalInfo,
          marginRight: "50px",
          fontSize: 20,
          bottom: 10,
          fontWeight: "bold",
        }}
      >
        ہدایات
      </div>
      <br></br>
      <div style={personalInfo}>
        <div style={section}>
          <ul dir="rtl" style={{ listStyleType: "none", fontSize: 15 }}>
            <li>
              <p
                className="ur"
                style={{ fontWeight: "lighter", lineHeight: 1 }}
              >
                <span className="font-bold">(i)</span>. جب امیر حلقہ رفیق کی
                فکری و عملی پختگی کے بارے میں اطمینان حاصل کرلے تو وہ متعلقہ
                رفیق کو نقیب بنانے کی توثیق فرما کر یہ فارم مرکز کو بھجوائے گا۔
              </p>
            </li>
            <li>
              <p
                className="ur"
                style={{ fontWeight: "lighter", lineHeight: 1 }}
              >
                <span className="font-bold">(ii)</span>. مقررہ دائرہ کے نقیب کا
                تقرر کرتے وقت امیر حلقہ یہ جائزہ فارم براہ راست مرکز کو پہنچائیں
                گے
              </p>
            </li>
            <li>
              <p
                className="ur"
                style={{ fontWeight: "lighter", lineHeight: 1.8 }}
              >
                <span className="font-bold">(ii)</span>. یہ یاد رہے کہ نقیب قرار
                دینے کا اختیار نظام العمل کے مطابق باقی امراء کو تفویض کیا گیا
                ہے اور اس ضمن میں ان سے امیر حلقہ سے مشاورت و اجازت کا تقاضا ہے
                اور مرکزی اس حوالے سے نگرانی کی ذمہ داری ہے۔ نقیب قرار دینے کے
                عمل میں کسی رخ کو بنیادی پر فیصلے کو تبدیل بھی کیا جا سکتا ہے۔
                لہذا گزارش ہے کہ کسی ریفریز کو نقیب قرار دیتے وقت تجلیت سے پرہیز
                کریں اور متعلقہ ریفریز کے فکر و عمل کے ضمن میں اطمینان کے ساتھ
                ساتھ تنظیمی ضابطوں کا خصوصی لحاظ رکھیں۔
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PrintNaqeebForm;
