import React, { useMemo } from "react";
import Switch from "react-switch";
import { Label } from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";

const GenericSwitch = React.memo(({ setValues, label, value }) => {
  const selectedLanguage = GetSelectedLanguage();
  const iconStyles = useMemo(
    () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      height: selectedLanguage === "ur" ? "" : "100%",
      fontSize: selectedLanguage === "ur" ? 15 : 12,
    }),
    [selectedLanguage]
  );

  const handleChange = (name) => (checked) => {
    setValues((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <Label htmlFor={`small-radius-switch-${label}`}>
      <Switch
        id={`small-radius-switch-${label}`}
        checked={value}
        onChange={handleChange(label)}
        handleDiameter={32}
        offColor="#96B6BE"
        onColor="#6ba3af"
        offHandleColor="#fff"
        onHandleColor="#55848e"
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.7)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={26}
        width={58}
        borderRadius={20}
        uncheckedIcon={
          <div style={iconStyles}>
            <GetLanguageString props="No" />
          </div>
        }
        checkedIcon={
          <div style={iconStyles}>
            <GetLanguageString props="Yes" />
          </div>
        }
      />
    </Label>
  );
});

export default GenericSwitch;
