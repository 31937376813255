import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  applyClassToCharacters,
  getStringFromJSX,
  isUrdu,
} from "../../../../constants/const";
import GenericSwitch from "../../../shared/GenericSwitch";

const FormStepDeen = ({ setValues, values }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return (
    <>
      <Row>
        {" "}
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  فرض نمازوں (خصوصافجروعشاء ) کو پابندی کے ساتھ باجماعت ادا کرتے
                  ہیں؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isPrayingObligatoryPrayersInMasjid"
                  value={values.isPrayingObligatoryPrayersInMasjid}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  وضع قطع (داڑھی و لباس وغیرہ) میں سنّت نبوی کا لحاظ کرتے ہیں؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isMaintainingPhysicallyAccordingToSunnah"
                  value={values.isMaintainingPhysicallyAccordingToSunnah}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  غیر مخلوط معاشرت اور پردے کا کس قدر اہتمام کرتے ہیں؟
                </Label>
              </Col>

              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isMaintainingPardah"
                  value={values.isMaintainingPardah}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  نظام العمل اور راہنمااصول کا مطالعہ کر لیا ہے؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isReadNizamUlAmalAndRahnumaAsool"
                  value={values.isReadNizamUlAmalAndRahnumaAsool}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              {" "}
              تعلق مع اللہ (عبادات، اخلاقیات، معاملات) کی کیفیت
            </Label>
            <Input
              type="text"
              name="taluqMaAllah"
              className={isUrdu(values.taluqMaAllah) ? "ur " : ""}
              value={values.taluqMaAllah}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              ذریعہ معاش کیا ہے؟
            </Label>
            <Input
              disabled={values.sourceOfIncome}
              type="text"
              name="sourceOfIncome"
              className={isUrdu(values.sourceOfIncome) ? "ur " : ""}
              value={getStringFromJSX(
                applyClassToCharacters(values.sourceOfIncome, "ur")
              )}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              مالی و دیگر معاملات کیسے ہیں؟
            </Label>
            <Input
              type="text"
              name="financialStatus"
              className={isUrdu(values.financialStatus) ? "ur " : ""}
              value={values.financialStatus}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur">دیگر احوال و امور</Label>
            <Input
              type="text"
              name="otherInformation"
              className={isUrdu(values.otherInformation) ? "ur " : ""}
              value={values.otherInformation}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormStepDeen;
