import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import GenericSwitch from "../../../shared/GenericSwitch";

const FormStepTanzeem = ({ setValues, values }) => {
  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  ملتزم رفیق قرار پائے کم از کم ایک سال کا عرصہ گزر چکا ہے؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isOneYearMultazim"
                  value={values.isOneYearMultazim}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  ملتزم تربیتی کورس مکمل کر چکے ہیں؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isMultzimCourseCompleted"
                  value={values.isMultzimCourseCompleted}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  ملتزم نصاب میں شامل کتب کے مضامین کا مطالعہ یا سماعت مکمل کر
                  چکے ہیں ؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isMultazimNisaabCompleted"
                  value={values.isMultazimNisaabCompleted}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  نقباء کورس کر چکے ہیں؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isNuqabaCourseCompleted"
                  value={values.isNuqabaCourseCompleted}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  کیا اجتماعات میں باقاعدگی سے (کل وقتی اور بروقت) شریک ہوتے ہیں
                  ؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isRegularInIjtimaat"
                  value={values.isRegularInIjtimaat}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  بامر مجبوری شرکت نہ کر سکنے پر پیشگی رخصت طلب کرنے کا اہتمام
                  کرتے ہیں؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isInformingNotAttendingIjtimaInCaseOfEmergency"
                  value={values.isInformingNotAttendingIjtimaInCaseOfEmergency}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  کیا باقاعدگی سے انفاق کی سعادت حاصل کرتے ہیں ؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isGivingFundRegularly"
                  value={values.isGivingFundRegularly}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  مشاورت و تنقید کے ضمن میں آداب کو ملحوظ رکھتے ہیں؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isRespectfulInGivingSuggestion"
                  value={values.isRespectfulInGivingSuggestion}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormStepTanzeem;
