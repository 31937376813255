import React, { useEffect, useState } from "react";
import headerImage from "../../content/css/img/printHeader.png";
import PrintMuavaneenForm from "../member/MemberResponsibility/MuavaneenResponsibilityRequestForm/PrintMuavaneenForm";
import PrintNaqeebForm from "../member/MemberResponsibility/NaqeebResponsibilityRequestForm/PrintNaqeebForm";

const PrintAssignResponsibilityNotification = ({
  printData,
  responsibilityData,
  printType,
  calledFrom,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    if (!imageLoaded) {
      const img = new Image();
      img.src = headerImage;
      img.onload = () => setImageLoaded(true);
    }
  }, [imageLoaded]);

  const containerStyle = {
    width: "100%",
    top: calledFrom === "notifications" ? -195 : 0,
    background: "#fff",
    color: "#000",
  };

  return (
    <>
      {printType === 3 ? (
        <div id="section-to-print" style={containerStyle} className="print">
          {/* Header Section */}
          <div style={{ position: "relative", textAlign: "center" }}>
            <img
              src={headerImage}
              alt="Tanzeem Islami Header"
              style={{ maxWidth: "100%" }}
            />
            {/* Overlay Text
            <h2
              className="ur header-text"
              style={{
                position: "absolute",
                top: "18%", // Adjust as needed
                right: "50%",
                transform: "translate(-50%, -50%)",
                color: "black", // Change color based on image background
                backgroundColor: "transparent", // Optional for better visibility
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              حلقہ : پنجاب شمالی
            </h2> */}
          </div>

          {/* Content Section */}
          {responsibilityData.responsibilityType === "naqeeb" ? (
            <PrintNaqeebForm printData={printData} />
          ) : (
            <PrintMuavaneenForm
              printData={printData}
              responsibilityData={responsibilityData}
              calledFrom={calledFrom}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default PrintAssignResponsibilityNotification;
