import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  applyClassToCharacters,
  getStringFromJSX,
  isUrdu,
} from "../../../../constants/const";
import { GetSelectedLanguage } from "../../../helper/Method";

const FormStepDeen = ({ handleCheckBoxes, values, setValues }) => {
  const selectedLanguage = GetSelectedLanguage();
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <Row>
        <Label className="col-form-label ur">
          <h4>ذاتی تربیت و تعلق ماللہ</h4>
        </Label>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              عبادات
            </Label>
            <Input
              type="text"
              name="ibadaat"
              className={isUrdu(values.ibadaat) ? "ur" : " "}
              value={values.ibadaat}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              اخلاقیات
            </Label>
            <Input
              type="text"
              name="ikhlaqiyat"
              className={isUrdu(values.ikhlaqiyat) ? "ur" : " "}
              value={values.ikhlaqiyat}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              معاملات
            </Label>
            <Input
              type="text"
              name="muamlaat"
              className={isUrdu(values.muamlaat) ? "ur" : " "}
              value={values.muamlaat}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              مطالعہ لٹریچر / دہرائی
            </Label>
            <Input
              type="text"
              name="literatureRevision"
              className={isUrdu(values.literatureRevision) ? "ur" : " "}
              value={values.literatureRevision}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label className="col-form-label ur">
          <h4>تمسک بالقرآن</h4>
        </Label>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              تلاوت قرآن
            </Label>
            <Input
              type="text"
              name="tilawatQuran"
              className={isUrdu(values.tilawatQuran) ? "ur" : " "}
              value={values.tilawatQuran}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              حفظ قرآن
            </Label>
            <Input
              type="text"
              name="hifzQuran"
              className={isUrdu(values.hifzQuran) ? "ur" : " "}
              value={values.hifzQuran}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              ترجمہ قرآن
            </Label>
            <Input
              type="text"
              name="tarjumaQuran"
              className={isUrdu(values.tarjumaQuran) ? "ur" : " "}
              value={values.tarjumaQuran}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              تفسیر قرآن
            </Label>
            <Input
              type="text"
              name="tafseeerQuran"
              className={isUrdu(values.tafseeerQuran) ? "ur" : " "}
              value={values.tafseeerQuran}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label className="col-form-label ur">
          <h4>دعوت دین</h4>
        </Label>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              انفرادی دعوت کا اہتمام
            </Label>
            <Input
              type="text"
              name="infiradiDawat"
              className={isUrdu(values.infiradiDawat) ? "ur" : " "}
              value={values.infiradiDawat}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              اجتماعی دعوتی سرگرمیوں میں حصہ
            </Label>
            <Input
              type="text"
              name="attendanceInIjtimayiDawatiActivities"
              className={
                isUrdu(values.attendanceInIjtimayiDawatiActivities) ? "ur" : " "
              }
              value={values.attendanceInIjtimayiDawatiActivities}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              درس و تدریس / شرکت
            </Label>
            <Input
              type="text"
              name="darsOTadrees"
              className={isUrdu(values.darsOTadrees) ? "ur" : " "}
              value={values.darsOTadrees}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label
              className="col-form-label ur"
              style={{ fontSize: 15, fontWeight: "bold" }}
            >
              انفاق مال و وقت
            </Label>
            <Input
              type="text"
              name="infaaqMaalWaqt"
              className={isUrdu(values.infaaqMaalWaqt) ? "ur" : " "}
              value={values.infaaqMaalWaqt}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              گھریلو اسره
            </Label>
            <Input
              type="text"
              name="ghareluUsra"
              className={isUrdu(values.ghareluUsra) ? "ur" : " "}
              value={values.ghareluUsra}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              متبادل تربیت
            </Label>
            <Input
              type="text"
              name="mutabadilTarbiat"
              className={isUrdu(values.mutabadilTarbiat) ? "ur" : " "}
              value={values.mutabadilTarbiat}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label className="col-form-label ur">
          <h4>پابندی نظم</h4>
        </Label>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              اجتماعات میں حاضری
            </Label>
            <Input
              type="text"
              name="ateendanceInIjtimaat"
              className={isUrdu(values.ateendanceInIjtimaat) ? "ur" : " "}
              value={values.ateendanceInIjtimaat}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              ماہانہ / سہ ماہی / ششماہی رپورٹس
            </Label>
            <Input
              type="text"
              name="lfcReports"
              className={isUrdu(values.lfcReports) ? "ur" : " "}
              value={values.lfcReports}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label className="col-form-label ur">
          <h4>طرز معاشرت</h4>
        </Label>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              الیکٹرانک میڈیا کا استمعال
            </Label>
            <Input
              type="text"
              name="useOfElectronicMedia"
              className={isUrdu(values.useOfElectronicMedia) ? "ur" : " "}
              value={values.useOfElectronicMedia}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              غیر مسنون رسومات میں شرکت سے اجتناب
            </Label>
            <Input
              type="text"
              name="avoidGhairMasnoonRasomaat"
              className={isUrdu(values.avoidGhairMasnoonRasomaat) ? "ur" : " "}
              value={values.avoidGhairMasnoonRasomaat}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              غیر محرم و ملحوط محفلوں مین شرکت سے اجتناب
            </Label>
            <Input
              type="text"
              name="avoidAttendingMehfilGhairMehram"
              className={
                isUrdu(values.avoidAttendingMehfilGhairMehram) ? "ur" : " "
              }
              value={values.avoidAttendingMehfilGhairMehram}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              وضح قطع
            </Label>
            <Input
              type="text"
              name="physicalAppearance"
              className={isUrdu(values.physicalAppearance) ? "ur" : " "}
              value={values.physicalAppearance}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              رہن سہن (معیار زندگی )
            </Label>
            <Input
              type="text"
              name="wayOfLiving"
              className={isUrdu(values.wayOfLiving) ? "ur" : " "}
              value={values.wayOfLiving}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label
              className="col-form-label ur"
              style={{ fontSize: 15, fontWeight: "bold" }}
            >
              شرعی پردہ
            </Label>
            <Row>
              <Col md="6">
                <span
                  style={{
                    float: selectedLanguage === "ur" ? "right" : "left",
                    marginLeft: selectedLanguage === "ur" ? "24px" : "0px",
                  }}
                >
                  <Input
                    type="checkbox"
                    name="isSharayiPardaOutsideHome"
                    id="isSharayiPardaOutsideHome"
                    checked={values.isSharayiPardaOutsideHome}
                    value={!values.isSharayiPardaOutsideHome}
                    onChange={handleCheckBoxes}
                  />
                </span>
                <Label
                  check
                  className="ur"
                  style={{ fontSize: 14, marginLeft: 10 }}
                >
                  گھر میں
                </Label>
              </Col>
              <Col md="6">
                <span
                  style={{
                    float: selectedLanguage === "ur" ? "right" : "left",
                    marginLeft: selectedLanguage === "ur" ? "24px" : "0px",
                  }}
                >
                  <Input
                    type="checkbox"
                    name="isSharayiPardaInHome"
                    id="isSharayiPardaInHome"
                    checked={values.isSharayiPardaInHome}
                    value={!values.isSharayiPardaInHome}
                    onChange={handleCheckBoxes}
                  />
                </span>
                <Label
                  check
                  className="ur"
                  style={{ fontSize: 14, marginLeft: 10 }}
                >
                  گھر سے باہر
                </Label>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label className="col-form-label ur">
          <h4>معیشت </h4>
        </Label>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              ذریعہ معاش
            </Label>
            <Input
              disabled={values.sourceOfIncome}
              type="text"
              name="sourceOfIncome"
              className={isUrdu(values.sourceOfIncome) ? "ur " : ""}
              value={getStringFromJSX(
                applyClassToCharacters(values.sourceOfIncome, "ur")
              )}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              حرام بین و مکروہات تحریمی سے اجتناب
            </Label>
            <Input
              type="text"
              name="avoidHaram"
              className={isUrdu(values.avoidHaram) ? "ur" : " "}
              value={values.avoidHaram}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              معاملات ( مالی لین دین)
            </Label>
            <Input
              type="text"
              name="maliLainDainMuamlat"
              className={isUrdu(values.maliLainDainMuamlat) ? "ur" : " "}
              value={values.maliLainDainMuamlat}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormGroup>
            <Label className="col-form-label ur">
              دیگر قابل ذکر پہلو (اگر ہوں تو)
            </Label>
            <Input
              type="text"
              name="otherInformation"
              className={isUrdu(values.otherInformation) ? "ur " : ""}
              value={values.otherInformation}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormStepDeen;
