import moment from "moment";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { GetSelectedLanguage } from "../../../helper/Method";
import GenericSwitch from "../../../shared/GenericSwitch";
import { ApiMudaris } from "../../MemberMudaris/ApiMudaris";
import { isUrdu } from "../../../../constants/const";

const FormStepTanzeem = ({ setValues, values }) => {
  const [arabicTestStatuses, setArabicTestStatuses] = useState([]);
  const [tajweedTestStatuses, setTajweedTestStatuses] = useState([]);

  useEffect(() => {
    ApiMudaris()
      .getAssessmentStatuses(1)
      .then((res) => {
        setArabicTestStatuses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    ApiMudaris()
      .getAssessmentStatuses(2)
      .then((res) => {
        setTajweedTestStatuses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return (
    <>
      <Row>
        {" "}
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  ملتزم نصاب مکمل کر لیا ہے؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isMultazimNisaabComplete"
                  value={values.isMultazimNisaabComplete}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  ذمہ داران نصاب مکمل کر لیا ہے؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isZimedaranNisaabComplete"
                  value={values.isZimedaranNisaabComplete}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  مدرسین نصاب مکمل کر لیا ہے؟
                </Label>
              </Col>

              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isMudariseenNisaabComplete"
                  value={values.isMudariseenNisaabComplete}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  نظام العمل اور راہنمااصول کا مطالعہ کر لیا ہے؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isReadNizamUlAmalAndRahnumaAsool"
                  value={values.isReadNizamUlAmalAndRahnumaAsool}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur">عربی ٹیسٹ</Label>
            <Select
              options={arabicTestStatuses}
              value={values.arabicGrammarTestStatus}
              className="basic-single ur"
              isClearable
              classNamePrefix="select"
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              onChange={(a) => {
                setValues({
                  ...values,
                  arabicGrammarTestStatus: a,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur">تجوید ٹیسٹ</Label>
            <Select
              options={tajweedTestStatuses}
              value={values.tajweebTestStatus}
              className="basic-single ur"
              isClearable
              classNamePrefix="select"
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              onChange={(t) => {
                setValues({
                  ...values,
                  tajweebTestStatus: t,
                });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              شمولیت
            </Label>
            <Input
              type="text"
              name="joiningDate"
              disabled
              value={
                values.joiningDate
                  ? moment(values.joiningDate).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              ملتزم قرار پائے
            </Label>
            <Input
              type="text"
              name="multazimDate"
              disabled
              value={
                values.multazimDate
                  ? moment(values.multazimDate).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              ملتزم تربیتی کورس
            </Label>
            <Input
              type="text"
              name="multazimCourseDate"
              disabled
              value={
                values.multazimCourseDate
                  ? moment(values.multazimCourseDate).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              نقباء کورس
            </Label>
            <Input
              type="text"
              name="nuqabaCourseDate"
              disabled
              value={
                values.nuqabaCourseDate
                  ? moment(values.nuqabaCourseDate).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              مدرسین کورس
            </Label>
            <Input
              type="text"
              name="mudariseenCourse"
              disabled
              value={
                values.mudariseenCourse
                  ? moment(values.mudariseenCourse).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              مدرسین ریفریشر کورس
            </Label>
            <Input
              type="text"
              name="mudariseenRefresherCourse"
              disabled
              value={
                values.mudariseenRefresherCourse
                  ? moment(values.mudariseenRefresherCourse).format(
                      "DD/MM/yyyy"
                    )
                  : "-"
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              امراءو نقباء تربیتی و مشاورتی اجتماع
            </Label>
            <Input
              type="text"
              name="umraNuqabaCourse"
              disabled
              value={
                values.umraNuqabaCourse
                  ? moment(values.umraNuqabaCourse).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              دینی تعلیم
            </Label>
            <Input
              type="text"
              name="deeniEducation"
              className={isUrdu(values.deeniEducation) ? "ur" : " "}
              value={values.deeniEducation}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              دنیوی تعلیم
            </Label>
            <Input
              type="text"
              name="duniyawiEducation"
              className={isUrdu(values.duniyawiEducation) ? "ur" : " "}
              value={values.duniyawiEducation}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormStepTanzeem;
