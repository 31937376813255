import React from "react";
import DatePicker from "react-datepicker";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { isUrdu } from "../../../../constants/const";
import { GetLanguageString } from "../../../helper/Components";
import { encryptStorage } from "../../../../constants/EncryptStorage";

const FormStepTasurat = ({ values, setValues }) => {
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return (
    <>
      <Row>
        {unitTypeName === "Tanzeem" ? (
          <Col md={"12"}>
            <div className="ibox-content">
              <Row>
                <Col md="10">
                  <h4>
                    <GetLanguageString
                      props={
                        "member__request_feedback_sifarish_assign_responsibility"
                      }
                    />
                  </h4>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <DatePicker
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mm/yyyy"
                      className="form-control text-center"
                      selected={new Date()}
                      dateFormat="dd/MM/yyyy"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label className="col-form-label ur ">تبصرہ و تاثرات</Label>
                  <Input
                    type="text"
                    name="muqamiAmeerFeedback"
                    className={isUrdu(values.muqamiAmeerFeedback) ? "ur" : " "}
                    value={values.muqamiAmeerFeedback}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">
                      <GetLanguageString
                        props={"quick_reports3_muqami_ameer"}
                      />
                    </Label>
                    <Input
                      disabled
                      type="text"
                      name="muqamiAmeerName"
                      className={isUrdu(values.muqamiAmeerName) ? "ur" : " "}
                      value={values.muqamiAmeerName}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Col>
        ) : unitTypeName === "Halqa" ? (
          <Col md={"12"}>
            <div className="ibox-content">
              <Row>
                <Col md={"10"}>
                  <h4>
                    <GetLanguageString
                      props={"member__request_validation_assign_responsibility"}
                    />
                  </h4>
                </Col>
                <Col md={"2"}>
                  <FormGroup>
                    <DatePicker
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd/mm/yyyy"
                      className="form-control text-center"
                      selected={new Date()}
                      dateFormat="dd/MM/yyyy"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={"6"}>
                  <FormGroup>
                    <Label className="col-form-label ur">تبصرہ و تاثرات</Label>
                    <Input
                      type="text"
                      name="ameerHalqaFeedback"
                      className={isUrdu(values.ameerHalqaFeedback) ? "ur" : ""}
                      value={values.ameerHalqaFeedback}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={"6"}>
                  <FormGroup>
                    <Label className="col-form-label">
                      <GetLanguageString props={"quick_reports3_ameer_halqa"} />
                    </Label>
                    <Input
                      disabled
                      type="text"
                      name="ameerHalqaName"
                      className={isUrdu(values.ameerHalqaName) ? "ur" : ""}
                      value={values.ameerHalqaName}
                    />
                  </FormGroup>
                </Col>
              </Row>{" "}
            </div>{" "}
          </Col>
        ) : null}
      </Row>
      <br></br>
      <hr></hr>
      <Row className="m-0">
        <div>
          <h3>Instructions :</h3>
          <p dir="rtl" className="ur" style={{ fontSize: 16 }}>
            مقامی و حلقہ جاتی معاونین کے تقرر کے وقت یہ بھی ضروری ہے کے ملتزم
            قرار پائے کم از کم 2 سال گزر چکے ہوں اور ملتزم تربیتی کورس میں بھی
            شرکت مکمل ہو .{" "}
          </p>
        </div>
      </Row>
    </>
  );
};

export default FormStepTasurat;
