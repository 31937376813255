import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Logout from "../logout/Logout";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { requestAccountApi } from "../../actions/api";
import ChangePasswordModal from "./profile/ChangePassword";
import { encryptStorage } from "../../constants/EncryptStorage";
import jwtDecode from "jwt-decode";
import UserNotifications from "./topbar/UserNotifications";
import { CheckPermission, isUrdu } from "../../constants/const";
import swal from "sweetalert";
import { addMember } from "../member/ApiMember";
import { GoSignOut } from "react-icons/go";

const languageMap = {
  en: { label: "English", dir: "ltr", active: true },
  ur: { label: "اردو", dir: "rtl", active: false },
};

const Header = ({ history, setValue, value, setLoading }) => {
  const selected = GetSelectedLanguage();
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const userid = parseInt(encryptStorage.getItem("userId"));
  const responsibilityId = parseInt(encryptStorage.getItem("responsibilityId"));
  const responsibilityName = encryptStorage.getItem("responsibilityName");

  const { t } = useTranslation();
  const [returnLogout, setReturnLogout] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [loggedInResposibility, setLoggedInResposibility] = useState(null);
  const [memberResposibilityDropDown, setMemberResposibilityDropDown] =
    useState([]);
  const [memberUnitDropDown, setMemberUnitDropDown] = useState([]);
  const [loggedInDropDownOpen, setLoggedInDropDownOpen] = useState(false);
  const toggle = () => setDropDownOpen((prevState) => !prevState);
  const loggedInToggle = () =>
    setLoggedInDropDownOpen((prevState) => !prevState);

  const [modal, setModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isTogglePressed, setIsTogglePressed] = useState(false);
  useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  const [formValue, setFormValue] = useState("");

  if (responsibilityName !== "responsibility" && loggedInResposibility === null)
    setLoggedInResposibility(responsibilityName);

  useEffect(() => {
    if (
      memberResposibilityDropDown?.length === 0 &&
      memberUnitDropDown?.length === 0
    ) {
      addMember()
        .getDropDown(memberId)
        .then((res) => {
          setMemberResposibilityDropDown(res.data.responsibilities);
          setMemberUnitDropDown(res.data.units);
          // if (responsibilityName === "responsibility") {
          if (res.data.responsibilities !== null) {
            if (responsibilityId === res.data.responsibilities[0].value)
              setLoggedInResposibility(res.data.responsibilities[0].label);
            else {
              setLoggedInResposibility(
                res.data.responsibilities.find(
                  (e) => e.value === responsibilityId
                ).label
              );
            }
          } else if (res.data.units !== null) {
            setLoggedInResposibility(res.data.units[0].label);
          }
          // }
        })
        .catch((err) => console.log(err));
    }
  }, [
    memberId,
    memberResposibilityDropDown,
    memberUnitDropDown,
    responsibilityId,
  ]);

  const toggle1 = () => {
    setValue(!value);
    setIsTogglePressed((prevState) => !prevState);
  };

  const handleClick = () => {
    setReturnLogout(true);
  };
  const handleChangePassword = () => {
    setModal(true);
  };

  const changeMemberResponsibilityOrUnit = (responsibilityId, unitId) => {
    setLoggedInResposibility(null);
    setLoading(true);
    requestAccountApi()
      .changeResponsibility(responsibilityId, unitId, userid.toString())
      .then((res) => {
        encryptStorage.setItem("userClaims", res.data.userClaims);
        const getToken = res.data.token;
        var decodedToken = jwtDecode(getToken);
        if (decodedToken.role === "admin")
          encryptStorage.setItem("canManage", "true");
        else encryptStorage.setItem("canManage", decodedToken.CanManage);

        encryptStorage.setItem(
          "responsibilityName",
          decodedToken.ResponsibilityName
        );
        encryptStorage.setItem("memberId", decodedToken.MemberId);
        encryptStorage.setItem("userId", decodedToken.UserName);
        encryptStorage.setItem("loggedInUnitId", decodedToken.UnitId);
        encryptStorage.setItem("unitTypeName", decodedToken.UnitTypeName);

        encryptStorage.setItem(
          "responsibilityId",
          decodedToken.ResponsibilityId
        );
        encryptStorage.setItem("token", JSON.stringify(getToken));
        encryptStorage.setItem(
          "refreshToken",
          JSON.stringify(res.data.refreshToken)
        );
        history.replace("/");

        window.onload = function () {
          // This function will be called once the page has finished reloading
          setLoading(false);
        };
        // Reload the page
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/memberList`,
      state: {
        formValue: formValue,
      },
    });
    setFormValue("");
  };

  if (returnLogout) return <Logout />;

  return (
    <>
      {modal ? (
        <ChangePasswordModal
          setModal={setModal}
          setReturnLogout={setReturnLogout}
        />
      ) : null}
      <div className="gray-bg">
        <div className="row border-bottom noprint">
          <nav
            className="navbar navbar-static-top white-bg"
            role="navigation"
            style={{
              marginBottom: "10px",
              position: "fixed",
              paddingRight:
                GetSelectedLanguage() === "en"
                  ? isTogglePressed
                    ? "100px"
                    : "240px"
                  : "",
              paddingLeft:
                GetSelectedLanguage() === "ur"
                  ? isTogglePressed
                    ? "100px"
                    : "240px"
                  : "",
              zIndex: 200,
            }}
          >
            <div className="navbar-header">
              <Row>
                <Col md={GetSelectedLanguage() === "en" ? "2" : "3"}>
                  {" "}
                  <div
                    className="navbar-minimalize minimalize-styl-2 btn btn-primary"
                    onClick={toggle1}
                  >
                    <i className="fa fa-bars"></i>
                  </div>
                </Col>
                <Col md={GetSelectedLanguage() === "en" ? "10" : "9"}>
                  {" "}
                  <Form onSubmit={handleSubmit} className="navbar-form-custom">
                    <FormGroup>
                      {CheckPermission("CanViewMember") ? (
                        <Input
                          type="text"
                          placeholder={t("header_search_for_something")}
                          value={formValue}
                          className={
                            isUrdu(formValue) || GetSelectedLanguage() === "ur"
                              ? "ur"
                              : ""
                          }
                          autoFocus
                          style={{ width: 1000 }}
                          onChange={(e) => {
                            setFormValue(e.target.value);
                          }}
                          onBlur={(e) => {
                            if (!e.relatedTarget) {
                              e.target.focus();
                            }
                          }}
                        />
                      ) : null}
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </div>
            <ul className="nav navbar-top-links navbar-right">
              {memberResposibilityDropDown?.length !== 0 ||
              memberUnitDropDown?.length !== 0 ? (
                <>
                  <li>
                    <span className="m-r-sm text-muted welcome-message">
                      {<GetLanguageString props="header_log_in" />}
                    </span>
                  </li>
                  <li className="m-r">
                    <Dropdown
                      isOpen={loggedInDropDownOpen}
                      toggle={loggedInToggle}
                    >
                      <DropdownToggle className="ur">
                        {loggedInResposibility}
                      </DropdownToggle>
                      <DropdownMenu>
                        {memberResposibilityDropDown?.map((record, index) => {
                          return (
                            <DropdownItem
                              className="ur"
                              key={index}
                              onClick={() => {
                                changeMemberResponsibilityOrUnit(
                                  record.value,
                                  0,
                                  record.label
                                );
                              }}
                            >
                              {record.label}
                            </DropdownItem>
                          );
                        })}
                        {memberUnitDropDown?.map((record, index) => {
                          return (
                            <DropdownItem
                              className="ur"
                              key={index}
                              onClick={() => {
                                changeMemberResponsibilityOrUnit(
                                  0,
                                  record.value,
                                  record.label
                                );
                              }}
                            >
                              {record.label}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                </>
              ) : null}
              <UserNotifications />
              <li className="m-r">
                <Button
                  className="m-l-sm"
                  color="info"
                  onClick={handleChangePassword}
                >
                  {<GetLanguageString props="change_password" />}
                </Button>
              </li>
              <li>
                <span className="m-l-sm m-r-sm text-muted welcome-message">
                  {<GetLanguageString props="header_select_language" />}
                </span>
              </li>
              <li>
                <Dropdown isOpen={dropDownOpen} toggle={toggle}>
                  <DropdownToggle
                    onClick={({ currentTarget }) =>
                      setMenuAnchor(currentTarget)
                    }
                    className={selected === "ur" ? "ur" : "en"}
                  >
                    {languageMap[selected].label}{" "}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem disabled>
                      {<GetLanguageString props="header_select_language" />}
                    </DropdownItem>
                    {Object.keys(languageMap)?.map((item) => (
                      <DropdownItem
                        key={item}
                        onClick={() => {
                          i18next.changeLanguage(item);
                          setMenuAnchor(null);
                        }}
                      >
                        {languageMap[item].label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </li>
              <li>
                <Link onClick={handleClick} to="#">
                  <GoSignOut
                    color="grey"
                    size={20}
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="common_log_out" />}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default withRouter(Header);
