import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { isUrdu } from "../../../../constants/const";
import { GetLanguageString } from "../../../helper/Components";
import { GetSelectedLanguage } from "../../../helper/Method";
import { setupApi } from "../../ApiMember";

const FormStepPersonal = ({ setValues, values, handleCheckBoxes }) => {
  const [maritalStatusList, setMaritalStatusList] = useState();
  const selectedLanguage = GetSelectedLanguage();
  useEffect(() => {
    setupApi()
      .getMaritalStatus()
      .then((res) => {
        setMaritalStatusList(res.data);
      })
      .catch((err) => console.log(err));

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              <GetLanguageString props={"member_father_name"} />
            </Label>
            <Input
              type="text"
              name="fatherName"
              disabled
              className={
                isUrdu(values.memberFatherName)
                  ? "ur unCheckedIconText"
                  : "unCheckedIconText"
              }
              value={values.memberFatherName}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              <GetLanguageString props={"member_age"} />
            </Label>
            <Input
              type="text"
              name="memberAge"
              disabled
              className={
                isUrdu(values.memberAge)
                  ? "ur unCheckedIconText"
                  : "unCheckedIconText"
              }
              value={values.memberAge}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_marital_status" />}
            </Label>
            <Select
              options={maritalStatusList}
              value={values.memberMaritalStatus}
              className="basic-single ur"
              isClearable
              classNamePrefix="select"
              placeholder={selectedLanguage === "en" ? "Select" : "منتخب کریں"}
              onChange={(ms) => {
                setValues({
                  ...values,
                  memberMaritalStatus: ms,
                });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>
              <GetLanguageString
                props={"member__request_additional_responsibilities"}
              />
            </Label>
            {values.additionalResponsibilities.length > 0 ? (
              <div
                style={{
                  borderRadius: "8px",
                  padding: "10px",
                  background: "rgb(241, 241, 241)", // Light background for contrast
                }}
              >
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {values.additionalResponsibilities.map((role, index) => (
                    <h4
                      key={index}
                      style={{
                        background: "rgb(219, 233, 236)",
                        padding: "6px 12px",
                        borderRadius: "5px",
                        borderLeft: "4px solid rgb(33, 88, 100)",
                        whiteSpace: "nowrap",
                        margin: "0", // Ensures no unwanted spacing
                      }}
                      className="ur"
                    >
                      {role}
                    </h4>
                  ))}
                </div>
              </div>
            ) : (
              <Input type="text" disabled className="ur" value={"کوئی نہیں "} />
            )}
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>
              <GetLanguageString
                props={"member__request_previous_responsibilities"}
              />
            </Label>
            {values.previousResponsibilities.length > 0 ? (
              <div
                style={{
                  borderRadius: "8px",
                  padding: "10px",
                  background: "rgb(241, 241, 241)", // Light background for contrast
                }}
              >
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {values.previousResponsibilities.map((role, index) => (
                    <h4
                      key={index}
                      style={{
                        background: "rgb(219, 233, 236)",
                        padding: "6px 12px",
                        borderRadius: "5px",
                        borderLeft: "4px solid rgb(33, 88, 100)",
                        whiteSpace: "nowrap",
                        margin: "0", // Ensures no unwanted spacing
                      }}
                      className="ur"
                    >
                      {role}
                    </h4>
                  ))}
                </div>
              </div>
            ) : (
              <Input type="text" disabled className="ur" value={"کوئی نہیں "} />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label className="col-form-label">
          <GetLanguageString
            props={"member__request_responsibility_additonal_skills"}
          />
        </Label>
        <Col md="4">
          <FormGroup check>
            <Label check className="ur">
              <span style={{ marginLeft: 10, fontSize: 14 }}>
                اکاؤنٹس سے متعلق تعلیم و تجربہ
              </span>
            </Label>
            <span
              style={{
                float: selectedLanguage === "ur" ? "right" : "left",
                marginLeft: selectedLanguage === "ur" ? "24px" : "0px",
              }}
            >
              <Input
                type="checkbox"
                name="knowledgeAboutAccounts"
                id="knowledgeAboutAccounts"
                className="big-checkbox"
                checked={values.knowledgeAboutAccounts}
                value={!values.knowledgeAboutAccounts}
                onChange={handleCheckBoxes}
              />
            </span>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup check>
            <Label check className="ur">
              <span style={{ marginLeft: 10, fontSize: 14 }}>
                کمپیوٹر کا استعمال
              </span>
            </Label>{" "}
            <span
              style={{
                float: selectedLanguage === "ur" ? "right" : "left",
                marginLeft: selectedLanguage === "ur" ? "24px" : "0px",
              }}
            >
              <Input
                type="checkbox"
                name="knowledgeAboutComputerUse"
                id="knowledgeAboutComputerUse"
                className="big-checkbox"
                checked={values.knowledgeAboutComputerUse}
                value={!values.knowledgeAboutComputerUse}
                onChange={handleCheckBoxes}
              />
            </span>
          </FormGroup>
        </Col>
        <Col md="4">
          {" "}
          <FormGroup check>
            <Label check className="ur">
              <span style={{ marginLeft: 10, fontSize: 14 }}>
                تنظیمی مالیاتی ہدایت نامے (Financial Manual) کا مطالعہ
              </span>
            </Label>{" "}
            <span
              style={{
                float: selectedLanguage === "ur" ? "right" : "left",
                marginLeft: selectedLanguage === "ur" ? "24px" : "0px",
              }}
            >
              <Input
                type="checkbox"
                className="big-checkbox"
                name="knowledgeAboutFinancialManual"
                id="knowledgeAboutFinancialManual"
                checked={values.knowledgeAboutFinancialManual}
                value={!values.knowledgeAboutFinancialManual}
                onChange={handleCheckBoxes}
              />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormStepPersonal;
